import React, { useState, useCallback } from 'react';
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Container,
  Paper,
  styled
} from '@mui/material';

const GradientButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#185519',
  margin: '10px',
  textAlign: 'center',
  transition: 'background-position 1s, color 0.3s',
  backgroundSize: '200% auto',
  color: '#FFF',
  display: 'block',
  '&:hover': {
    backgroundPosition: 'right center',
    color: '#696969',
    textDecoration: 'none',
    willChange: 'background-position, color',
  },
}));

const ContactUs = () => {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = useCallback(async (event) => {
    event.preventDefault();
    setSubmitting(true);
    const formData = new FormData(event.target);

    formData.append("access_key", "1f2dd32a-bbb2-4338-935c-34b6baee8bc9");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    try {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: json
      }).then((res) => res.json());

      if (res.success) {
        console.log("Success", res);
        setSubmitted(true);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setSubmitting(false);
      setTimeout(() => setSubmitted(false), 3000); // Reset after 3 seconds
    }
  }, []);

  return (
    <Container id="contact" maxWidth="lg" sx={{ pt: 3, pb: 5 }}>
      <Grid container spacing={4}>
        {/* Left side: Contact information */}
        <Grid item xs={12} md={8}>
          <Typography variant="h2" color="#093028" sx={{
            fontSize: {
              xs: '2rem',
              sm: '2.5rem',
              md: '3.75rem',
            },
            fontVariant: 'small-caps',
            fontWeight: 'bold',
            textAlign: {
              xs: 'center',
              sm: 'center',
              md: 'start'
            },
            fontFamily: '"Montserrat", sans-serif'
          }}>
            Contact us
          </Typography>

          <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column' }}>
            {[
              { title: 'OFFICE HOURS', content: 'Monday-Saturday\n8:00 am to 5:00 pm' },
              { title: 'EMAIL', content: 'info@jeevanfoods.co' },
              { title: 'CALL US', content: '+92 335 8311083' },
              { title: 'OUR ADDRESS', content: 'Office No.23 Agha Qadirdad Khan, Zarai Market Near Barrage\nSukkur, Pakistan' }
            ].map((item, index) => (
              <React.Fragment key={index}>
                <Typography variant="overline" fontWeight="bold" color="rgba(34,126,34,1)" sx={{
                  fontFamily: '"Montserrat", sans-serif',
                  marginTop: '1vh'
                }}>
                  {item.title}
                </Typography>
                <Typography variant="p" sx={{
                  fontFamily: '"Montserrat", sans-serif',
                  whiteSpace: 'pre-line'
                }}>
                  {item.content}
                </Typography>
              </React.Fragment>
            ))}
          </Box>
        </Grid>

        {/* Right side: Contact form */}
        <Grid item xs={12} md={4}>
          <Paper 
            elevation={0} 
            sx={{ 
              p: 4, 
              borderRadius: '1vh',
              background: '#1e201e',
            }}
          >
            <Box component="form" noValidate onSubmit={onSubmit}>
              {['name', 'email'].map((field) => (
                <TextField
                  key={field}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  placeholder={`Enter your ${field}`}
                  name={field}
                  type={field === 'email' ? 'email' : 'text'}
                  sx={{ bgcolor: 'white', borderRadius: 1 }}
                />
              ))}
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                multiline
                rows={4}
                placeholder="Write us your question here..."
                name="message"
                sx={{ bgcolor: 'white', borderRadius: 1 }}
              />
              <GradientButton
                type="submit"
                variant="contained"
                size="small"
                disabled={submitting || submitted}
                sx={{
                  mt: 2,
                  textTransform: 'none',
                  px: 2,
                  py: 1,
                  fontVariant: 'all-petite-caps',
                }}
              >
                {submitting ? 'Submitting form...' : submitted ? 'Form submitted' : 'Send message'}
              </GradientButton>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactUs;